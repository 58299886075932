<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DELAY, MIN_QUERY_LENGTH } from '@/constants/search';
import { USER_ROLES_TYPES } from '@/constants/user';

export default {
  name: 'Roles',
  data() {
    return {
      isLoading: false,
      isModalShowed: false,
      selectedUser: '',
      searchResults: [],
      searchLoading: false,
      rows: [],
    };
  },
  computed: {
    ...mapState('user', ['loggedInUser']),
    hasAccess() {
      return this.loggedInUser?.roles.includes(USER_ROLES_TYPES.SUPER)
        || this.loggedInUser?.roles.includes(USER_ROLES_TYPES.BIB_EMPLOYEE);
    },
  },
  methods: {
    searchUsers(query) {
      if (query?.length >= MIN_QUERY_LENGTH) {
        this.searchLoading = true;
        this.$API.user.search({ query }).then(({ data }) => {
          this.searchResults = data;
        }).finally(() => {
          this.searchLoading = false;
        });
      } else {
        this.searchResults = [];
      }
    },
    showModal() {
      this.isModalShowed = true;
    },
    checkHasSame(userList, selectedUser) {
      const hasSame = userList.some((user) => (user.login === selectedUser));
      if (hasSame) {
        this.$EsmpNotify.$show('Выбранный пользователь уже добавлен', 'error');
        return true;
      }
      return false;
    },
    getSelectedUserLogin(userList, selectedUser) {
      return userList.find((el) => el.login === selectedUser)?.login;
    },
  },
  created() {
    this.debouncedSearchUsers = debounce(this.searchUsers, SEARCH_DELAY);
  },
};
</script>
