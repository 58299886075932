<template>
  <esmp-table-wrapper
    class="roles"
    title="Управление ролями"
    :is-column-settings-button-showed="false"
    :is-allow-full-page="false"
  >
    <template #header-actions>
      <esmp-button
        v-if="hasAccess"
        size="small"
        @click="showModal"
      >
        Добавить
      </esmp-button>
    </template>
    <esmp-table
      :columns="columns"
      :rows="rows"
      :loading="isLoading"
      stripe
    >
      <template #cell-roles="{ tr }">
        <esmp-select
          :value="tr.roles"
          postfix="plus"
          multiple
          @on-select-selected="toggleRole($event, tr)"
          :disabled="!hasAccess"
        >
          <esmp-select-option
            v-for="r in excludeUserRole(mappedRoles)"
            :key="r.code"
            :value="r.code"
          >
            {{ r.name }}
          </esmp-select-option>
        </esmp-select>
      </template>
      <template #cell-privilege="{ tr }">
        <esmp-checkbox
          v-if="isUserHasRole(tr, USER_ROLES_TYPES.PRIVILEGED_USER)"
          disabled
          :value="true"
        />
      </template>
    </esmp-table>
    <esmp-modal
      v-model="isModalShowed"
      class="add-user"
      @on-ok="addUser"
    >
      <template #header>
        Добавить модератора или администратора
      </template>
      <validation-observer ref="form">
        <validation-provider
          rules="required"
          :name="`«Пользователь»`"
          v-slot="v"
          tag="div"
        >
          <esmp-select
            class="settings__user-search__item"
            v-model="selectedUser"
            placeholder="Выберите пользователя"
            :remote-method="debouncedSearchUsers"
            :loading="searchLoading"
            filterable
            clearable
          >
            <esmp-select-option
              v-for="item in searchResults"
              :value="item.login"
              :key="item.login"
              :label="item.fullName"
            >
              <span class="settings__user-search__item-name">
                {{ item.fullName }}
              </span>
              <span class="settings__user-search__item-email">
                {{ item.email }}
              </span>
            </esmp-select-option>
          </esmp-select>
          <div class="settings__error-message">
            {{ v.errors.length ? v.errors[0] : '' }}
          </div>
        </validation-provider>
      </validation-observer>
      <esmp-select
        v-model="newUserRole"
        placeholder="Выберите роль"
        class="mt-20"
        multiple
      >
        <esmp-select-option
          v-for="r in excludeUserRole(mappedRoles)"
          :key="r.code"
          :value="r.code"
        >
          {{ r.name }}
        </esmp-select-option>
      </esmp-select>
    </esmp-modal>
  </esmp-table-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES, USER_ROLES_TYPES, ROLE_ACTION_TYPE } from '@/constants/user';
import settingsPage from '@/mixins/settings-page.vue';

export default {
  name: 'Roles',
  mixins: [settingsPage],
  data() {
    return {
      newUserRole: null,
      columns: [
        {
          title: 'ID',
          key: 'employeeId',
        },
        {
          title: 'Имя',
          key: 'fullName',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: 'Роли',
          key: 'roles',
        },
        {
          title: 'Привилегированный пользователь',
          key: 'privilege',
        },
      ],
      USER_ROLES_TYPES: Object.freeze(USER_ROLES_TYPES),
      USER_ROLES: Object.freeze(USER_ROLES),
    };
  },
  computed: {
    ...mapGetters('user', ['mappedRoles']),
  },
  methods: {
    ...mapActions('user', ['getRoles']),
    async getAdditionRolesUsers() {
      return this.$API.user.getAdditionRolesUsers()
        .then(({ data }) => {
          this.rows = data;
        });
    },
    toggleRole(event, user) {
      const isExist = user.roles.includes(event.value);
      if (isExist) {
        this.removeRole(user, [event.value]);
      } else {
        this.addRole(user, [event.value]);
      }
    },
    updateRolesList(user, roles, action) {
      const localUser = this.rows.find((i) => i.employeeId === user.employeeId);
      const localUserIndex = this.rows.findIndex((i) => i.employeeId === user.employeeId);

      if (action === this.ROLE_ACTION_TYPE.ADD_ROLE) {
        if (localUser) {
          localUser.roles = localUser.roles.concat(roles);
          this.rows[localUserIndex] = localUser;
        } else {
          this.rows.push({ ...user, roles });
        }
      } else if (action === this.ROLE_ACTION_TYPE.REMOVE_ROLE) {
        const localRoles = new Set(roles);
        localUser.roles = localUser.roles.filter((role) => !localRoles.has(role));
        const rolesWithoutRoleUser = localUser.roles.filter((role) => role !== this.USER_ROLES_TYPES.USER);
        if (rolesWithoutRoleUser?.length) {
          this.rows[localUserIndex] = localUser;
        } else {
          this.rows = this.rows.filter((i) => i.employeeId !== user.employeeId);
        }
      }
    },
    removeRole(user, roles) {
      this.$API.user.removeRole(user.employeeId, user.login, roles.join(','))
        .then(() => {
          this.updateRolesList(user, roles, this.ROLE_ACTION_TYPE.REMOVE_ROLE);
        });
    },
    addRole(user, roles) {
      this.$API.user.addRole(user.employeeId, user.login, roles)
        .then(() => {
          this.updateRolesList(user, roles, this.ROLE_ACTION_TYPE.ADD_ROLE);
        })
        .finally(() => {
          this.isModalShowed = false;
          this.selectedUser = '';
        });
    },
    addUser() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // проверка дублей
          if (this.checkHasSame(this.rows, this.selectedUser)) {
            return;
          }

          const selectedUserLogin = this.getSelectedUserLogin(this.searchResults, this.selectedUser);
          if (!selectedUserLogin) {
            this.$EsmpNotify.$show('Невозможно получить данные выбранного пользователя', 'error');
            return;
          }

          const selectedUser = this.searchResults.find((el) => el.login === this.selectedUser);
          selectedUser.employeeId = this.selectedUser;
          this.addRole(selectedUser, this.newUserRole);
        }
      });
    },
    isUserHasRole(userData, role) {
      return userData?.roles?.includes(role);
    },
    excludeUserRole(roles) {
      return roles.filter((role) => (role.code ? role.code !== USER_ROLES_TYPES.USER : role !== USER_ROLES_TYPES.USER));
    },
  },
  async created() {
    this.isLoading = true;
    await this.getRoles();
    await this.getAdditionRolesUsers();
    this.ROLE_ACTION_TYPE = Object.freeze(ROLE_ACTION_TYPE);
    this.isLoading = false;
  },
};
</script>
